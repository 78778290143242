import { FunctionComponent } from 'react';
import { PortalContainer } from '../../components/PortalContainer';
import Grid from '@mui/material/Grid';

// https://tempuslabs.atlassian.net/browse/NP-2093
const KitReturn: FunctionComponent = () => {
  return (
    <PortalContainer>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        Kit Return Component
      </Grid>
    </PortalContainer>
  );
};

export default KitReturn;
