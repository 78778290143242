import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import { oktaAuth } from '../api/Okta';
import packageJson from '../../../package.json';
import config from '../../config';
import { AuthenticationType } from '../api/AuthenticationType';
import { ConsentAppVerify } from '@tempus/patient-forms-service-shared';

type DateOfBirthValues = {
  month: string;
  day: string;
  year: string;
};

type SetIdentityTokenV2Values = {
  dateOfBirth: DateOfBirthValues;
  firstName?: string;
  lastName?: string;
};

const MOBILE_BREAKPOINT = 800;

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

const isMobileDevice = (window: WindowDimensions): boolean => {
  return window.width <= MOBILE_BREAKPOINT;
};

type useComponentVisibleType = {
  ref: any;
  isComponentVisible: boolean;
  setIsComponentVisible: (isVisible: boolean) => void;
};

// This function can be used to detect click events outside the provided ref component and trigger open/close actions
const useComponentVisible = (initialIsVisible: boolean): useComponentVisibleType => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref, isComponentVisible, setIsComponentVisible };
};

const createDynamicStyleObject = (key: string, val: string | number): StyleDeclarationValue => {
  const styleSheet = StyleSheet.create({
    dynamicStyle: {
      [key]: val,
    },
  });
  return styleSheet.dynamicStyle;
};

const getAccessToken = async (): Promise<string> => {
  const tokenManager = oktaAuth.tokenManager;
  const accessToken = (await tokenManager.get('accessToken')) as AccessToken;
  if (!accessToken?.value) {
    throw new Error('Unable to fetch access token');
  } else if (tokenManager.hasExpired(accessToken)) {
    throw new Error('Access token has expired');
  }
  return accessToken.value;
};

let identityToken = '';

export const setIdentityTokenV3AndGetLockoutStatus = async (
  values: SetIdentityTokenV2Values,
  orderId: string,
): Promise<ConsentAppVerify> => {
  const result = await fetchHelper<ConsentAppVerify>(`/v3/consent-app/verification-token`, {
    authenticationType: AuthenticationType.NONE,
    method: 'POST',
    body: {
      orderId: orderId,
      dateOfBirth: `${values.dateOfBirth.month}/${values.dateOfBirth.day}/${values.dateOfBirth.year}`,
      firstName: values.firstName,
      lastName: values.lastName,
    },
  });

  if (result && result.successful) {
    if (!result.identityToken) {
      throw new Error(`Identity token not returned`);
    }

    identityToken = result.identityToken;
  }

  return result;
};

const getIdentityToken = (path: string) => {
  if (identityToken === '') {
    throw new Error(`Identity token was not set! Unable to authorize API: ${path}`);
  } else {
    return identityToken;
  }
};

// This is currently only used by unit tests
export const resetIdentityToken = (): void => {
  identityToken = '';
};

const createGenericContext = <T extends unknown>(): any => {
  const genericContext = createContext<T | undefined>(undefined);

  const useGenericContext = () => {
    const contextIsDefined = useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error('Context must be used within a Provider');
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};

type RequestOptions = {
  authToken?: string;
  authenticationType?: AuthenticationType;
  body?: any;
  method?: string;
  baseUrl?: string;
};

export class HttpError extends Error {
  status: number;

  constructor(res: Response) {
    super(res.statusText);
    this.status = res.status;
  }
}

export default async function fetchHelper<T>(path: string, options: RequestOptions = {}): Promise<T> {
  const {
    authToken,
    method = 'GET',
    body,
    authenticationType = AuthenticationType.OKTA,
    baseUrl = config.baseUrl,
  } = options;

  const userAgent = navigator.userAgent;
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    'X-Tempus-App-Version': packageJson.version,
    'X-User-Agent': userAgent,
  };
  if ([AuthenticationType.OKTA, AuthenticationType.AUTH0].includes(authenticationType)) {
    if (authToken) {
      headers.Authorization = 'Bearer ' + authToken;
    }
  } else if (authenticationType === AuthenticationType.IDENTITY) {
    headers['identity'] = getIdentityToken(path);
  }

  const res = await fetch(baseUrl + path, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }).catch((e) => {
    // Network failures cause fetch to reject instead of fulfilling with an http error.
    throw e;
  });

  if (!res.ok) {
    const errResponse = await res.json();
    throw new HttpError({
      statusText: errResponse.statusCode,
      status: errResponse.status,
    } as Response);
  }
  if (res.headers.get('content-type')?.includes('application/json')) {
    return res.json() as any;
  }
  if (res.headers.get('content-type')?.includes('application/pdf')) {
    return res.blob() as any;
  } else {
    return res.text() as any;
  }
}

export {
  MOBILE_BREAKPOINT,
  getWindowDimensions,
  isMobileDevice,
  useComponentVisible,
  createDynamicStyleObject,
  getAccessToken,
  createGenericContext,
};
