import { FunctionComponent } from 'react';
import { Warning } from '@tcl-boron-icons/icons';
import Grid from '@mui/material/Grid';
import { Alert } from '@tcl-boron-prefabs/alert';
import { Button } from '@tcl-boron-prefabs/button';
import { WarningPalette } from '@tcl-boron-colors/colors';
import { PortalContainer } from '../components/PortalContainer';

// TODO: Color of warning is different than mocks #FFC56C
// TODO: Add full UI for Order Tracking/Report Delivery page https://tempuslabs.atlassian.net/browse/NP-2084
const Home: FunctionComponent = () => {
  return (
    <PortalContainer>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item padding="18px 24px 16px 24px">
          <Alert status="warning">
            <div style={{ display: 'flex', width: '100%' }}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center' }}>
                  <Warning name="warning" color={WarningPalette[700]} />
                  <p>
                    <strong>Missing Items</strong>
                  </p>
                </div>
                <p style={{ paddingBottom: '15px' }}>You have the following items that is delaying your test results</p>
                <ul style={{ paddingLeft: '14px' }}>
                  <li>
                    <p>Your kit has not been returned.</p>
                  </li>
                </ul>
                <Button
                  style={{ height: '32px' }}
                  ariaLabel="kit-return"
                  buttonType="secondary"
                  onClick={() => console.log('clicked')}
                >
                  {' '}
                  Kit return options{' '}
                </Button>
              </div>
            </div>
          </Alert>
        </Grid>
        <Grid item> TODO: Bottom column component </Grid>
      </Grid>
    </PortalContainer>
  );
};

export default Home;
