import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Button } from '@tcl-boron-prefabs/button';
import { Box } from '@mui/material';
import { PortalContainer } from '../../components/PortalContainer';
import OnboardingHeader from '../../components/OnboardingHeader';
import fetchHelper from '../../../utils';
import config from '../../../../config';
import { NavigationPath } from '../../../api/Navigation';
import { AuthenticationType } from '../../../api/AuthenticationType';
import { useAuth0 } from '@auth0/auth0-react';

const OnboardingTermsOfUseStyles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainTextContainer: {
    padding: '30px 40px 30px 30px',
  },
  detailText: {
    margin: '0px',
  },
  button: {
    margin: '24px',
  },
});

const OnboardingTermsOfUse: FunctionComponent = () => {
  const { push } = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async () => {
    const response = await fetchHelper<{ isAccepted: boolean }>('/terms-of-use/accept', {
      method: 'POST',
      baseUrl: config.patientWebServiceBaseUrl,
      authenticationType: AuthenticationType.AUTH0,
      authToken: await getAccessTokenSilently({
        authorizationParams: {
          audience: config.patientWebServiceBaseUrl,
        },
      }),
    });
    if (!response.isAccepted) {
      throw new Error('Accept terms of use endpoint in PWS returned isAccepted false - something went wrong');
    }

    push(NavigationPath.PROFILE);
  };

  return (
    <PortalContainer>
      <Box className={css(OnboardingTermsOfUseStyles.container)}>
        <Box>
          <OnboardingHeader text={'Terms of Use'} />
          <Box className={css(OnboardingTermsOfUseStyles.mainTextContainer)}>
            <p className={css(OnboardingTermsOfUseStyles.detailText)}>
              In order to use the patient portal, please review the following terms and indicate your agreement using
              the button below:
            </p>

            <p>
              <a href="https://www.tempus.com/terms-of-use/">Terms of Use</a>,{' '}
              <a href={'https://www.tempus.com/privacy/'}>Privacy Policy</a>, and{' '}
              <a href="https://www.tempus.com/notice-of-privacy-practices/">Notice of Privacy Practices</a>
            </p>
          </Box>
        </Box>
        <Button ariaLabel={'I agree'} className={css(OnboardingTermsOfUseStyles.button)} onClick={onSubmit}>
          I agree
        </Button>
      </Box>
    </PortalContainer>
  );
};

export default OnboardingTermsOfUse;
