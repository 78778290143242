import { useState } from 'react';
import { Menu as MenuIcon, LinkOut, Close } from '@tcl-boron-icons/icons';
import { IconButton } from '@tcl-boron-prefabs/icon-button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link, useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import React from 'react';
import { SequentialPalette } from '@tcl-boron-colors/colors';
import { useMediaQuery, useTheme } from '@mui/material';
import { Menu } from '@tcl-boron-prefabs/menu';

const useStyles = createUseStyles({
  primaryMenuItem: {
    lineHeight: '20px',
    fontSize: '14px',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: SequentialPalette['ext100'],
      color: SequentialPalette[200],
      borderColor: SequentialPalette['ext100'],
      fontWeight: 'bold',
    },
  },
  secondaryMenuItem: {
    lineHeight: '14px',
    fontSize: '12px',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: SequentialPalette['ext100'],
      color: SequentialPalette[200],
      borderColor: SequentialPalette['ext100'],
      fontWeight: 'bold',
    },
  },
  divider: {
    borderBottom: '1px solid #D8D8D8',
    width: '106px',
    marginLeft: '16px',
  },
});
export const PortalContainer: React.FC = ({ children }) => {
  const { divider, primaryMenuItem, secondaryMenuItem } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const menuItems = [
    [
      {
        text: 'PGx Report Status',
        id: '/portal',
        openNewTab: false,
      },
      {
        text: 'Contact Information',
        id: '/profile',
        openNewTab: false,
      },
      {
        text: 'Payment Details',
        id: '/payment_details',
        openNewTab: false,
      },
      {
        text: 'Return Options',
        id: '/kit_return',
        openNewTab: false,
      },
      {
        text: 'PRO Assessments',
        id: '/pro',
        openNewTab: false,
      },
    ],
    [
      {
        text: 'About Us',
        id: 'https://www.tempus.com/patients/neuro-psych/', // TODO: Move into environment config: https://tempuslabs.atlassian.net/browse/NP-2203
        Icon: LinkOut,
        openNewTab: true,
      },
      {
        text: 'Terms of Use',
        id: '/onboarding_terms_of_use',
        openNewTab: false,
      },
      {
        text: 'Consent to Test',
        id: '/consent_v2',
        openNewTab: false,
      },
      {
        text: 'Log Out',
        id: '/logout', // TODO: Auth0 Integration
        openNewTab: false,
      },
    ],
  ];
  const history = useHistory();

  return (
    <Grid container style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box width="100%" sx={{ boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)' }}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          flexDirection="row"
          padding={{ xs: '12px', sm: '16px', md: '20px', lg: '20px', xl: '20px' }}
        >
          <Grid item xs={1}>
            {isLargeScreen ? (
              <Menu
                classes={{
                  menuItemRowEnabled: primaryMenuItem,
                }}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                menuItems={menuItems}
                onChange={(menuItemId) => {
                  // If the menu item is a URL, open it in a new tab
                  if (menuItemId?.startsWith('https://')) {
                    window.open(menuItemId, '_blank');
                  } else {
                    history.push(menuItemId!);
                  }
                }}
              >
                <IconButton name="Home Menu" Icon={MenuIcon} onClick={() => setIsOpen(true)} ariaLabel="menuIcon" />
              </Menu>
            ) : isOpen ? (
              <IconButton name="Close Menu" Icon={Close} onClick={() => setIsOpen(false)} ariaLabel="closeIcon" />
            ) : (
              <IconButton name="Home Menu" Icon={MenuIcon} onClick={() => setIsOpen(true)} ariaLabel="menuIcon" />
            )}
          </Grid>
          <Grid item xs={10} justifyContent="center" display="flex">
            <img src="https://go.tempus.com/rs/833-QRC-346/images/Tempus-Logo.png" alt="Tempus Logo" height="16px" />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
      {isOpen && !isLargeScreen ? (
        <Grid container flexDirection="column" border="1px" padding="8px 0px 8px 0px">
          {menuItems.map((menuItemGroup, index) => (
            <React.Fragment key={index}>
              {menuItemGroup.map((menuItem, subIndex) => (
                <Link
                  key={subIndex}
                  className={index === 0 ? primaryMenuItem : secondaryMenuItem}
                  to={menuItem.openNewTab ? '' : menuItem.id}
                  onClick={() => {
                    if (menuItem.openNewTab) {
                      window.open(menuItem.id, '_blank');
                    }
                  }}
                  target={menuItem.openNewTab ? '_blank' : '_self'}
                >
                  <Grid
                    item
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    padding="6px 16px 6px 16px"
                    gap="8px"
                  >
                    <div>
                      {menuItem.text} {menuItem.Icon ? <menuItem.Icon height="12px" /> : null}
                    </div>
                  </Grid>
                </Link>
              ))}
              {index !== menuItems.length - 1 && <div className={divider} />}
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <Box style={{ flex: 1, display: 'flex' }}>{children}</Box>
      )}
    </Grid>
  );
};
