import { FunctionComponent } from 'react';
import { PortalContainer } from '../../components/PortalContainer';
import Grid from '@mui/material/Grid';

// TODO: https://tempuslabs.atlassian.net/browse/NP-2200
const ConsentV2: FunctionComponent = () => {
  return (
    <PortalContainer>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        Consent Component
      </Grid>
    </PortalContainer>
  );
};

export default ConsentV2;
