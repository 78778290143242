import React, { StrictMode, useEffect } from 'react';
import { AppNavigationRoutes } from '../api/Navigation';
import { initAnalytics } from '../api/Pendo';
import AppNavigator from './components/navigation/AppNavigator';
import ErrorBoundary from './components/screen/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';

const App = (): JSX.Element => {
  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <StrictMode>
      <ErrorBoundary>
        <Router>
          <AppNavigator routes={AppNavigationRoutes} />
        </Router>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
