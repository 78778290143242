import { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { styles } from '../../constants/styles';
import { Box } from '@mui/material';

const ScreenHeadingTextStyles = StyleSheet.create({
  container: {
    padding: '30px 40px 10px 25px',
  },
  text: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const OnboardingHeader: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <Box className={css(ScreenHeadingTextStyles.container)}>
      <p className={css(styles.bodyHeader, ScreenHeadingTextStyles.text)}>{text}</p>
    </Box>
  );
};

export default OnboardingHeader;
