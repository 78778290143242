import React from 'react';
import { NavigationRoute, AuthenticationState } from '../../../api/Navigation';
import VerifiedRoute from './VerifiedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import Auth0Route from './Auth0Route';

export type RouteWrapperProps = NavigationRoute;

const RouteWrapper: React.FC<RouteWrapperProps> = (props) => {
  const RouteMapper = {
    [AuthenticationState.UNAUTHENTICATED]: UnauthenticatedRoute,
    [AuthenticationState.AUTHENTICATED]: AuthenticatedRoute,
    [AuthenticationState.VERIFIED]: VerifiedRoute,
    [AuthenticationState.AUTH0_AUTHENTICATED]: Auth0Route,
  };

  const Route = RouteMapper[props.authState];

  return <Route {...props} />;
};

export default RouteWrapper;
